// FREE
import Animation from "./components/Animation";
import Badge from "./components/Badge";
import Breadcrumb from "./components/Breadcrumb";
import BreadcrumbItem from "./components/BreadcrumbItem";
import Button from "./components/Button";
import ButtonGroup from "./components/ButtonGroup";
import ButtonToolbar from "./components/ButtonToolbar";
import Card from "./components/Card";
import CardBody from "./components/CardBody";
import CardFooter from "./components/CardFooter";
import CardGroup from "./components/CardGroup";
import CardHeader from "./components/CardHeader";
import CardImage from "./components/CardImage";
import CardText from "./components/CardText";
import CardTitle from "./components/CardTitle";
import Carousel from "./components/Carousel";
import CarouselCaption from "./components/CarouselCaption";
import CarouselControl from "./components/CarouselControl";
import CarouselInner from "./components/CarouselInner";
import CarouselItem from "./components/CarouselItem";
import CarouselIndicators from "./components/CarouselIndicators";
import CarouselIndicator from "./components/CarouselIndicator";
import Col from "./components/Col";
import Collapse from "./components/Collapse";
import Container from "./components/Container";
import DataTable from "./components/DataTable";
import DropdownItem from "./components/DropdownItem";
import DropdownMenu from "./components/DropdownMenu";
import DropdownToggle from "./components/DropdownToggle";
import EdgeHeader from "./components/EdgeHeader";
import Fa from "./components/Fa";
import FormInline from "./components/FormInline";
import Footer from "./components/Footer";
import FreeBird from "./components/FreeBird";
import Input from "./components/Input";
import InputNumeric from "./components/InputNumeric";
import Jumbotron from "./components/Jumbotron";
import ListGroup from "./components/ListGroup";
import ListGroupItem from "./components/ListGroupItem";
import Mask from "./components/Mask";
import Media from "./components/Media";
import Modal from "./components/Modal";
import ModalBody from "./components/ModalBody";
import ModalFooter from "./components/ModalFooter";
import ModalHeader from "./components/ModalHeader";
import Nav from "./components/Nav";
import Navbar from "./components/Navbar";
import NavbarBrand from "./components/NavbarBrand";
import NavbarNav from "./components/NavbarNav";
import NavbarToggler from "./components/NavbarToggler";
import NavItem from "./components/NavItem";
import NavLink from "./components/NavLink";
import Pagination from "./components/Pagination";
import PageItem from "./components/PageItem";
import PageLink from "./components/PageLink";
import Popover from "./components/Popover";
import PopoverBody from "./components/PopoverBody";
import PopoverHeader from "./components/PopoverHeader";
import Progress from "./components/Progress";
import Waves from "./components/Waves";
import Row from "./components/Row";
import Table from "./components/Table";
import TableBody from "./components/TableBody";
import TableHead from "./components/TableHead";
import TableFoot from "./components/TableFoot";
import TextField from "./components/TextField";
import Tooltip from "./components/Tooltip";
import View from "./components/View";
import { toast, ToastContainer, cssTransition } from "react-toastify";
import HamburgerToggler from "./components/HamburgerToggler";
import Iframe from "./components/Iframe";

// MDB FREE
import MDBBadge from "./components/Badge";
import MDBBreadcrumb from "./components/Breadcrumb";
import MDBBreadcrumbItem from "./components/BreadcrumbItem";
import MDBBtn from "./components/Button";
import MDBBtnGroup from "./components/ButtonGroup";
import MDBBtnToolbar from "./components/ButtonToolbar";
import MDBCard from "./components/Card";
import MDBCardBody from "./components/CardBody";
import MDBCardFooter from "./components/CardFooter";
import MDBCardGroup from "./components/CardGroup";
import MDBCardHeader from "./components/CardHeader";
import MDBCardImage from "./components/CardImage";
import MDBCardText from "./components/CardText";
import MDBCardTitle from "./components/CardTitle";
import MDBCarousel from "./components/Carousel";
import MDBCarouselCaption from "./components/CarouselCaption";
import MDBCarouselControl from "./components/CarouselControl";
import MDBCarouselInner from "./components/CarouselInner";
import MDBCarouselItem from "./components/CarouselItem";
import MDBCarouselIndicators from "./components/CarouselIndicators";
import MDBCarouselIndicator from "./components/CarouselIndicator";
import MDBCol from "./components/Col";
import MDBCollapse from "./components/Collapse";
import MDBContainer from "./components/Container";
import MDBDataTable from "./components/DataTable";
import MDBDropdownItem from "./components/DropdownItem";
import MDBDropdownMenu from "./components/DropdownMenu";
import MDBDropdownToggle from "./components/DropdownToggle";
import MDBEdgeHeader from "./components/EdgeHeader";
import MDBIcon from "./components/Fa";
import MDBFormInline from "./components/FormInline";
import MDBFooter from "./components/Footer";
import MDBFreeBird from "./components/FreeBird";
import MDBInput from "./components/Input";
import MDBInputSelect from "./components/InputNumeric";
import MDBJumbotron from "./components/Jumbotron";
import MDBListGroup from "./components/ListGroup";
import MDBListGroupItem from "./components/ListGroupItem";
import MDBMask from "./components/Mask";
import MDBMedia from "./components/Media";
import MDBModal from "./components/Modal";
import MDBModalBody from "./components/ModalBody";
import MDBModalFooter from "./components/ModalFooter";
import MDBModalHeader from "./components/ModalHeader";
import MDBNav from "./components/Nav";
import MDBNavbar from "./components/Navbar";
import MDBNavbarBrand from "./components/NavbarBrand";
import MDBNavbarNav from "./components/NavbarNav";
import MDBNavbarToggler from "./components/NavbarToggler";
import MDBNavItem from "./components/NavItem";
import MDBNavLink from "./components/NavLink";
import MDBPagination from "./components/Pagination";
import MDBPageItem from "./components/PageItem";
import MDBPageNav from "./components/PageLink";
import MDBPopover from "./components/Popover";
import MDBPopoverBody from "./components/PopoverBody";
import MDBPopoverHeader from "./components/PopoverHeader";
import MDBProgress from "./components/Progress";
import MDBWaves from "./components/Waves";
import MDBRow from "./components/Row";
import MDBTable from "./components/Table";
import MDBTableBody from "./components/TableBody";
import MDBTableHead from "./components/TableHead";
import MDBTableFoot from "./components/TableFoot";
import MDBTextarea from "./components/TextField";
import MDBTooltip from "./components/Tooltip";
import MDBView from "./components/View";

let Dropdown = require("./components/Dropdown").default;
let DropdownMenuComponent = require("./components/DropdownMenuComponent")
  .default;

let MDBDropdown = require("./components/Dropdown").default;
let MDBDropdownMenuComponent = require("./components/DropdownMenuComponent")
  .default;

export {
  // FREE
  Animation,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardGroup,
  CardHeader,
  CardImage,
  CardText,
  CardTitle,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselInner,
  CarouselItem,
  CarouselIndicators,
  CarouselIndicator,
  Col,
  Collapse,
  Container,
  DataTable,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownMenuComponent,
  DropdownToggle,
  EdgeHeader,
  Fa,
  FormInline,
  Footer,
  FreeBird,
  Input,
  InputNumeric,
  Jumbotron,
  ListGroup,
  ListGroupItem,
  Mask,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarNav,
  NavbarToggler,
  NavItem,
  NavLink,
  Pagination,
  PageItem,
  PageLink,
  Popover,
  PopoverBody,
  PopoverHeader,
  Progress,
  Waves,
  Row,
  Table,
  TableBody,
  TableHead,
  TableFoot,
  TextField,
  Tooltip,
  View,
  toast,
  ToastContainer,
  cssTransition,
  HamburgerToggler,
  Iframe,
  // MDB FREE
  MDBBadge,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBBtnGroup,
  MDBBtnToolbar,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardGroup,
  MDBCardHeader,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselControl,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselIndicators,
  MDBCarouselIndicator,
  MDBCol,
  MDBCollapse,
  MDBContainer,
  MDBDataTable,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownMenuComponent,
  MDBDropdownToggle,
  MDBEdgeHeader,
  MDBIcon,
  MDBFormInline,
  MDBFooter,
  MDBFreeBird,
  MDBInput,
  MDBInputSelect,
  MDBJumbotron,
  MDBListGroup,
  MDBListGroupItem,
  MDBMask,
  MDBMedia,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBNav,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBProgress,
  MDBWaves,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTableFoot,
  MDBTextarea,
  MDBTooltip,
  MDBView
};
